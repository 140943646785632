<template>
  <infinite-scroll @load="fetchConsumers">
    <user-table
      :users="formatedUsers"
      :fields="fields"
      :actions="actions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      no-local-sorting
    />
  </infinite-scroll>
</template>

<script>
import userMixin from "./../mixins/userMixin";
import UserTable from "./../components/User/UserTable";
import InfiniteScroll from "./../components/Base/BaseInfiniteScroll";
export default {
  components: {
    UserTable,
    InfiniteScroll
  },
  mixins: [userMixin],
  data() {
    return {
      fetching: false,
      fields: [
        {
          key: "fullName",
          label: "nameAndSurname",
          sort: true,
          sortBy: "profile.name"
        },
        { key: "email", label: "email", sort: true },
        { key: "status", label: "status", sort: false },
        { key: "createdAt", label: "createdat", sort: true },
        { key: "registrationDate", label: "registrationDate", sort: true },
        { key: "blockedBy", label: "blocked", sort: false },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.manage",
          variant: "secondary",
          callbackFunc: this.gotoUserDetails
        },
        {
          label: "button.delete",
          variant: "orange",
          callbackFunc: this.openDeleteConfirmationModal
        },
        {
          label: "button.suspend",
          variant: "secondary",
          hidden: user => user.status === "active",
          callbackFunc: this.suspendUser
        },
        {
          label: "button.reactivate",
          variant: "orange",
          hidden: user => user.status === "suspended",
          callbackFunc: this.reactivateUser
        }
      ]
    };
  },
  mounted() {
    this.fetchConsumers(true);
  },
  computed: {
    consumers() {
      return this.$store.getters["consumer/getConsumerUsers"];
    },
    formatedUsers() {
      return this.consumers.map(item => {
        return {
          id: item._id,
          photo: this.$_getUserProfilePhoto(item.profile),
          fullName: this.$_getUserFullName(item.profile),
          email: item.email,
          status: item.status,
          blockedBy: item.blockedBy,
          createdAt: item.createdAt,
          registrationDate: item.registrationDate
        };
      });
    },
    sortBy: {
      get() {
        const sortBy = this.$store.getters["consumer/getSortBy"];
        return this.fields.find(f => f.sortBy === sortBy)?.key || sortBy;
      },
      set(value) {
        const sortBy = this.fields.find(f => f.key === value)?.sortBy || value;
        this.$store.commit("consumer/SET_SORT_BY", sortBy);
        this.fetchConsumers(true);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["consumer/getSortDesc"];
      },
      set(value) {
        this.$store.commit("consumer/SET_SORT_DESC", value);
        this.fetchConsumers(true);
      }
    }
  },
  methods: {
    fetchConsumers(initial = false) {
      this.$store.dispatch("consumer/retrieveConsumerUsers", {
        hasPagination: true,
        initial
      });
    }
  }
};
</script>

<style lang="scss"></style>
